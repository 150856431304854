var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "CustomFormRecordForm" },
    [
      _c(
        "form-panel",
        _vm._b(
          {
            ref: "formPanel",
            attrs: { form: _vm.form },
            on: { update: _vm.update },
          },
          "form-panel",
          _vm.submitConfig,
          false
        ),
        [
          _c(
            "col2-detail",
            [
              _c(
                "col2-block",
                { attrs: { title: "表单记录属性" } },
                [
                  _c("el-form-item", { attrs: { label: "填写人" } }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.form.inuser_name) +
                        "\n        "
                    ),
                  ]),
                  _c("el-form-item", { attrs: { label: "所属项目" } }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.form.community_name) +
                        "\n        "
                    ),
                  ]),
                  _c("el-form-item", { attrs: { label: "房号" } }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.form.house_name) +
                        "\n        "
                    ),
                  ]),
                  _c("el-form-item", { attrs: { label: "填写时间" } }, [
                    _vm._v(
                      "\n          " + _vm._s(_vm.form.intime) + "\n        "
                    ),
                  ]),
                  _c("el-form-item", { attrs: { label: "审核状态" } }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.recordStatusMap[_vm.form.approve_status]) +
                        "\n        "
                    ),
                  ]),
                  [1, 2, 3].includes(_vm.form.approve_status)
                    ? _c("el-form-item", { attrs: { label: "审核意见" } }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.form.approve_remarks) +
                            "\n        "
                        ),
                      ])
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "col2-block",
                { attrs: { title: "表单记录" } },
                _vm._l(_vm.formRecordList, function (item, index) {
                  return _c(
                    "div",
                    { key: index },
                    [
                      item.type == "imgupload"
                        ? _c(
                            "el-form-item",
                            { attrs: { label: item.name } },
                            _vm._l(
                              _vm.formItemMap(item),
                              function (imgItem, imgIndex) {
                                return _c(
                                  "div",
                                  { key: imgIndex, staticClass: "image-item" },
                                  [
                                    _c("img", {
                                      attrs: { src: imgItem },
                                      on: {
                                        click: function ($event) {
                                          return _vm.viewPic(imgItem)
                                        },
                                      },
                                    }),
                                  ]
                                )
                              }
                            ),
                            0
                          )
                        : item.type == "fileupload"
                        ? _c(
                            "el-form-item",
                            { attrs: { label: item.name } },
                            _vm._l(
                              _vm.formItemMap(item),
                              function (fileItem, fileIndex) {
                                return _c(
                                  "div",
                                  { key: fileIndex, staticClass: "file-item" },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "file-item-name" },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.getFileName(fileItem))
                                        ),
                                      ]
                                    ),
                                    _c("v-button", {
                                      attrs: { type: "text", text: "查看" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.downloadFile(fileItem)
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                )
                              }
                            ),
                            0
                          )
                        : item.type == "table"
                        ? _c(
                            "el-form-item",
                            { attrs: { label: item.name } },
                            [
                              _c("table-panel", {
                                attrs: {
                                  headers: _vm.tableHeaderMap(item),
                                  tableData: _vm.tableDataMap(item),
                                  operateColumnWidth: "80",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "operateSlot",
                                      fn: function (scope) {
                                        return [
                                          _c("v-button", {
                                            attrs: {
                                              text: "详情",
                                              type: "text",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.detailRowMethod(
                                                  item,
                                                  scope.row
                                                )
                                              },
                                            },
                                          }),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                            ],
                            1
                          )
                        : _c("el-form-item", { attrs: { label: item.name } }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.formItemMap(item)) +
                                "\n          "
                            ),
                          ]),
                    ],
                    1
                  )
                }),
                0
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.subFormDetailTitle,
            visible: _vm.dialogTableVisible,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogTableVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "pop-wrapper" },
            [
              _c(
                "form-panel",
                { attrs: { hasHeader: false } },
                [
                  _c(
                    "col2-detail",
                    _vm._l(
                      _vm.subFormRecordMap[_vm.itemKey],
                      function (item, index) {
                        return _c(
                          "div",
                          { key: index },
                          [
                            item.type == "imgupload"
                              ? _c(
                                  "el-form-item",
                                  { attrs: { label: item.name } },
                                  _vm._l(
                                    _vm.subFormItemMap(item),
                                    function (imgItem, imgIndex) {
                                      return _c(
                                        "div",
                                        {
                                          key: imgIndex,
                                          staticClass: "image-item",
                                        },
                                        [
                                          _c("img", {
                                            attrs: { src: imgItem },
                                            on: {
                                              click: function ($event) {
                                                return _vm.viewPic(imgItem)
                                              },
                                            },
                                          }),
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                )
                              : item.type == "fileupload"
                              ? _c(
                                  "el-form-item",
                                  { attrs: { label: item.name } },
                                  _vm._l(
                                    _vm.subFormItemMap(item),
                                    function (fileItem, fileIndex) {
                                      return _c(
                                        "div",
                                        {
                                          key: fileIndex,
                                          staticClass: "file-item",
                                        },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "file-item-name" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getFileName(fileItem)
                                                )
                                              ),
                                            ]
                                          ),
                                          _c("v-button", {
                                            attrs: {
                                              type: "text",
                                              text: "查看",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.downloadFile(
                                                  fileItem
                                                )
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    }
                                  ),
                                  0
                                )
                              : _c(
                                  "el-form-item",
                                  { attrs: { label: item.name } },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(_vm.subFormItemMap(item)) +
                                        "\n            "
                                    ),
                                  ]
                                ),
                          ],
                          1
                        )
                      }
                    ),
                    0
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }