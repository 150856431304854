<template>
  <div class="CustomFormRecordForm">
    <form-panel
      ref="formPanel"
      v-bind="submitConfig"
      :form="form"
      @update="update"
    >
      <col2-detail>
        <col2-block title="表单记录属性">
          <el-form-item label="填写人">
            {{ form.inuser_name }}
          </el-form-item>
          <el-form-item label="所属项目">
            {{ form.community_name }}
          </el-form-item>
          <el-form-item label="房号">
            {{ form.house_name }}
          </el-form-item>
          <el-form-item label="填写时间">
            {{ form.intime }}
          </el-form-item>
          <el-form-item label="审核状态">
            {{ recordStatusMap[form.approve_status] }}
          </el-form-item>
          <el-form-item v-if="[1, 2, 3].includes(form.approve_status)" label="审核意见">
            {{ form.approve_remarks }}
          </el-form-item>
        </col2-block>
        <col2-block title="表单记录">
          <div v-for="(item, index) in formRecordList" :key="index">

            <el-form-item v-if="item.type == 'imgupload'" :label="item.name">
              <div class="image-item" v-for="(imgItem, imgIndex) in formItemMap(item)" :key="imgIndex">
                <img :src="imgItem" @click="viewPic(imgItem)" />
              </div>
            </el-form-item>

            <el-form-item v-else-if="item.type == 'fileupload'" :label="item.name">
              <div class="file-item" v-for="(fileItem, fileIndex) in formItemMap(item)" :key="fileIndex">
                <span class="file-item-name">{{ getFileName(fileItem) }}</span>
                <v-button type="text" text="查看" @click="downloadFile(fileItem)" />
              </div>
            </el-form-item>

            <el-form-item v-else-if="item.type == 'table'" :label="item.name">
              <table-panel
                :headers="tableHeaderMap(item)"
                :tableData="tableDataMap(item)"
                operateColumnWidth="80">
                <template #operateSlot="scope">
                  <v-button text="详情" type="text" @click="detailRowMethod(item, scope.row)"></v-button>
                </template>
              </table-panel>
            </el-form-item>

            <el-form-item v-else :label="item.name">
              {{ formItemMap(item) }}
            </el-form-item>
          </div>
        </col2-block>
      </col2-detail>
    </form-panel>
    <el-dialog :title="subFormDetailTitle" :visible.sync="dialogTableVisible">
      <div class="pop-wrapper">
        <form-panel
        :hasHeader="false">
          <col2-detail>
            <div v-for="(item, index) in subFormRecordMap[itemKey]" :key="index">
              <el-form-item v-if="item.type == 'imgupload'" :label="item.name">
                <div class="image-item" v-for="(imgItem, imgIndex) in subFormItemMap(item)" :key="imgIndex">
                  <img :src="imgItem" @click="viewPic(imgItem)" />
                </div>
              </el-form-item>

              <el-form-item v-else-if="item.type == 'fileupload'" :label="item.name">
                <div class="file-item" v-for="(fileItem, fileIndex) in subFormItemMap(item)" :key="fileIndex">
                  <span class="file-item-name">{{ getFileName(fileItem) }}</span>
                  <v-button type="text" text="查看" @click="downloadFile(fileItem)" />
                </div>
              </el-form-item>

              <el-form-item v-else :label="item.name">
                {{ subFormItemMap(item) }}
              </el-form-item>
            </div>
          </col2-detail>
        </form-panel>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { queryRecodeDetailURL, queryDesignContentURL } from './api'
import { recordStatusMap } from './map'
import { Col2Detail, Col2Block, TablePanel } from 'components/bussiness'
import { downloadHelper } from 'common/utils'

export default {
  name: 'CustomFormRecordForm',
  components: {
    Col2Detail,
    Col2Block,
    TablePanel
  },
  data () {
    return {
      recordStatusMap,
      form: {},
      subForm: {},
      subFormDetailTitle: '',
      tableHeaders: {},
      formRecordList: [],
      subFormRecordMap: {},
      itemKey: '',
      submitConfig: {
        queryUrl: queryRecodeDetailURL
      },
      dialogTableVisible: false
    }
  },
  mounted () {
    const { formId, id, formName } = this.$route.query
    if (formName !== undefined) {
      const breadcrumb = `表单记录 —《${formName}》 > 详情`
      this.$setBreadcrumb(breadcrumb)
    } else {
      this.$setBreadcrumb('详情')
    }
    this.$refs.formPanel.getData({
      formId,
      id
    })
    this.queryInfo(formId)
  },
  methods: {
    formItemMap (item) {
      const model = item.model
      let value = undefined
      if (['select', 'checkbox', 'radio', 'usersex', 'switch', 'org', 'community', 'communityroom', 'area'].includes(item.type)) {
        value = this.form[model + '_display']
      } else if (['imgupload', 'fileupload'].includes(item.type)) {
        try {
          value = JSON.parse(this.form[model])
        } catch {}
      } else {
        value = this.form[model]
      }
      return value
    },
    subFormItemMap (item) {
      const model = item.model
      let value = undefined
      if (['select', 'checkbox', 'radio', 'usersex', 'switch', 'org', 'community', 'communityroom', 'area'].includes(item.type)) {
        value = this.subForm[model + '_display']
      } else if (['imgupload', 'fileupload'].includes(item.type)) {
        try {
          value = JSON.parse(this.subForm[model])
        } catch {}
      } else {
        value = this.subForm[model]
      }
      return value
    },
    handleTableHeaderData (tableItem) {
      const listFields = tableItem.tableColumns
      if (Array.isArray(listFields) && listFields.length) {
        const headers = []
        listFields.forEach(item => {
          if (item.options.showinlist) {
            if (['select', 'checkbox', 'radio', 'usersex', 'switch', 'org', 'community', 'communityroom', 'area'].includes(item.type)) {
              headers.push({
                prop: item.model + '_display',
                label: item.name
              })
            } else {
              headers.push({
                prop: item.model,
                label: item.name
              })
            }
          }
        })
        this.tableHeaders[tableItem.model] = headers
      }
    },
    // 返回对应子表单的表头数据
    tableHeaderMap (item) {
      return this.tableHeaders[item.model]
    },
    // 子表列表显示数据
    tableDataMap (item) {
      const model = item.model
      let value = []
      try {
        value = JSON.parse(this.form[model])
      } catch {}
      return value
    },
    // 查看子表单详情
    detailRowMethod (item, rowItem) {
      this.itemKey = item.model
      let form = {
        ...this.subForm,
        ...rowItem,
      }
      this.subForm = form
      this.subFormDetailTitle = `${item.name}详情`
      this.dialogTableVisible = true
    },
    // 处理子表单数据
    initSubModel (tableItem) {
      if (Array.isArray(tableItem.tableColumns) && tableItem.tableColumns.length) {
        const subFormRecordList = []
        tableItem.tableColumns.forEach(item => {
          if (item.type === 'text') {
            subFormRecordList.push({
              type: item.type,
              model: item.model,
              name: item.name
            })
            this.subForm[item.model] = item.options.defaultValue
          } else {
            if (item.options.dataBind) {
              subFormRecordList.push({
                type: item.type,
                model: item.model,
                name: item.name
              })
            }
          }
        })
        this.subFormRecordMap[tableItem.model] = subFormRecordList
      }
    },
    initModel (list) {
      if (Array.isArray(list) && list.length) {
        list.forEach(item => {
          if (item.type === 'grid') {
            item.columns.forEach(column => {
              this.initModel(column.list)
            })
          } else if (item.type === 'table') {
            this.formRecordList.push({
              type: item.type,
              model: item.model,
              name: item.name
            })
            this.handleTableHeaderData(item)
            this.initSubModel(item)
          } else if (item.type === 'text') {
            this.formRecordList.push({
              type: item.type,
              model: item.model,
              name: item.name
            })
            this.form[item.model] = item.options.defaultValue
          } else {
            if (item.options.dataBind) {
              this.formRecordList.push({
                type: item.type,
                model: item.model,
                name: item.name
              })
            }
          }
        })
      }
    },
    async queryInfo (id) {
      const params = {
        id
      }
      const { status, data } = await this.$axios.get(queryDesignContentURL, { params })
      if (status === 100) {
        const formData = JSON.parse(data)
        const list = formData.list
        this.initModel(list)
      }
    },
    update (data) {
      // 合并处理数据源
      let form = {
        ...data,
        ...this.form,
      }
      this.form = form
    },
    viewPic (src) {
      this.$previewBox([src])
    },
    getFileName (src) {
      return src.substr(src.lastIndexOf('\/') + 1)
    },
    downloadFile (src) {
      const imgType = ['gif', 'jpeg', 'jpg', 'bmp', 'png']
      if (RegExp('\.(' + imgType.join('|') + ')$', 'i').test(src)) {
        this.$previewBox([src])
      } else {
        downloadHelper.downloadFile({
          data: src,
          fileName: this.getFileName(src)
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.CustomFormRecordForm {
  .image-item {
    display: inline-block;
    margin-right: 20px;
    cursor: pointer;
    width: 140px;
    height: 140px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .file-item {
    .file-item-name {
      margin-right: 20px;
    }
  }
  .pop-wrapper {
    max-height: 600px;
    overflow: hidden;
    overflow-y: auto;
  }
}
</style>
